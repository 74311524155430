
import { Component, Vue } from 'vue-property-decorator';
import { URI_REGISTER_URL } from '@/api/eob/endpoints';
import { USER_NOTIFY } from '@/store/list';
import { namespace } from 'vuex-class';

const AppStore = namespace('AppStore');

@Component({
  name: 'Register'
})
export default class Register extends Vue {
  created (): void {
    this.fetchRegisterUrl();
  }

  @AppStore.Action(USER_NOTIFY) notify: any;

  async fetchRegisterUrl (): Promise<void> {
    try {
      const authSystemUrl = (await this.$api.post<any>(URI_REGISTER_URL)).data.url;
      this.handleSuccessResponse(authSystemUrl);
    } catch (e) {
      this.handleErrorResponse(e);
    }
  }

  handleSuccessResponse (authSystemUrl: string): void {
    const redirectUri = `${encodeURIComponent(window.location.origin)}/logowanie`;
    window.location.href = `${authSystemUrl}/zaloz-konto?redirect_uri=${redirectUri}`;
  }

  handleErrorResponse (e: XMLHttpRequest): void {
    this.notify({ msg: e, type: 'error', permanent: true });
  }
}
